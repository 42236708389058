@use '@kronoss/styles/globals' as *;
.FAQ {
  padding: 80px;

  @include lg {
    padding: 48px 0;
  }
  .Title {
    margin-bottom: 64px;
    text-align: center;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px; /* 109.524% */

    @include lg {
      font-size: 32px;
      line-height: 100%; /* 32px */
      letter-spacing: 1px;
    }
  }
  .FAQItems {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
