@use '@kronoss/styles/globals' as *;
.DropdownWrapper {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: var(--Grey-100-Card-Border-disabled, #f3f4f6);
  width: 100%;
  gap: 1.5rem;
  transition: 0.5s ease-in-out;

  .Dropdown {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
    cursor: pointer;

    .DropdownIconWrapper {
      svg {
        transition: 0.3s ease-in-out;
      }
    }
  }

  .DropdownItem {
    padding: 0 2rem 1.5rem;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    display: none;
    transition: 0.5s ease-in-out;
  }

  &.Open {
    border-radius: 0.5rem;

    .Dropdown {
      padding-bottom: 0;
      .DropdownIconWrapper {
        svg {
          transform: rotate(45deg);
        }
      }
    }

    .DropdownItem {
      animation: slide-in 0.3s ease-in-out forwards;
      display: block;
    }
  }
}
