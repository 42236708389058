@use '@kronoss/styles/globals' as *;
.BenefitsList {
  border-radius: var(--Corner-Large, 16px);
  background: var(--Brand-blue-500-primary, #2a5fac);
  padding: 24px;

  @include lg {
    padding: 48px 80px;
  }
  .BenefitsItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #dee8f7;
    .Title {
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      align-items: baseline;
      gap: 0.5rem;
      padding-bottom: 0.5rem;
    }
    .Subtitle {
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 125% */
    }
  }

  strong {
    color: #fff;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 30px; /* 93.75% */
  }
}
