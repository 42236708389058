@use '@kronoss/styles/globals' as *;
.SubscribeInput {
  display: flex;
  align-items: center;
  height: 50px;
  .Input {
    min-width: 250px;
    height: 100%;
    border-radius: 8px 0 0 8px;
    border: 2px solid #e5e7eb;
    background: #fbfdfe;
    padding: 1rem;
    color: #16325b;
    transition: 0.3s ease-in-out;
    &:focus,
    &:focus-visible {
      border: 2px solid #2a5fac;
      outline: none;
    }
    &::placeholder {
      color: #9ca3af;
    }
  }
  .SubscribeButton {
    height: 100%;
    padding: 1rem;
    color: #fbfdfe;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 0 8px 8px 0;
    border: 1px solid #2a5fac;
    background-color: #2a5fac;
    cursor: pointer;
  }
}
