@use '@kronoss/styles/globals' as *;
.Header {
  background: #f9fafb;
  padding: 1.5rem 0;

  &.Background {
    background: #dee8f7;
  }
  .HeaderInner {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 1.5rem;
    align-items: center;
    border-radius: 25px;
    background: #fbfdfe;

    .LogoWrapper {
      display: flex;
      align-items: center;
    }

    .Nav {
      display: flex;
      align-items: center;
      gap: 1rem;
      color: #16325b;
      margin: auto;
    }
    .Actions {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
}
