@use '@kronoss/styles/globals' as *;
.MobileHeaderContainer {
  display: flex;
  flex-direction: column;
  z-index: 20;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  position: fixed;

  .MobileHeaderWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: #fff;
    height: 80px;
    z-index: 21;
    transition: 0.3s ease-in-out;

    &.Background {
      background: #dee8f7;
    }

    &.OpenMenu {
      background: #fff;
    }

    .ButtonsWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;

      .SandwichButton {
        width: 1rem;
        height: 1rem;
        border: #16325b;
        border-radius: 0.45rem;
        padding: 1rem;
        position: relative;

        svg {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          color: #16325b;
        }
      }
    }
  }

  .ModalContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #fff;
    gap: 1rem;
    text-align: center;
    position: fixed;
    width: 100%;
    top: 80px;
    bottom: 0;
    z-index: 20;
    height: fit-content;
    transform: translateY(-200%);
    transition: 0.3s ease-in-out;
    border-radius: 0 0 16px 16px;
    padding: 16px 24px 0;

    &.Open {
      transform: translateY(0%);
    }

    .NavLink {
      width: 100%;
      padding: 0 1rem;
    }

    .ActionsWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      padding-bottom: 1rem;

      .ActionLink {
        width: 100%;

        .BookDemoButton {
          width: 100%;
        }
      }
    }
  }
}
