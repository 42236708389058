@use '@kronoss/styles/globals' as *;
.Benefits {
  padding: 80px 0;
  .TitleWrapper {
    .Title {
      text-align: center;
      font-size: 42px;
      font-style: normal;
      font-weight: 700;
      line-height: 46px; /* 109.524% */

      @include lg {
        font-size: 32px;
        line-height: 110%; /* 35.2px */
        margin-bottom: 12px;
      }
    }
    .Subtitle {
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 120% */
      margin-bottom: 40px;

      @include lg {
        font-size: 16px;
        line-height: 20px; /* 125% */
      }
    }
  }

  .BenefitsWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
