@use '@kronoss/styles/globals' as *;
.Toast {
  display: flex;
  background: #2a5fac;
  border: 1px solid #2a5fac !important;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  border-radius: 12px;
  width: 25rem;
  min-width: 4.375rem;
  min-height: 4.375rem;

  .InfoWrapper {
    display: flex;
    gap: 1rem;
    padding: 0.5rem 1rem;

    svg {
      color: white;
    }

    .MessageWrapper {
      gap: 0.75rem;

      .Label {
        color: #fbfdfe;
        font-size: 14px;
        line-height: 1.125rem;
        margin: 0;
      }

      .Message {
        font-size: 14px;
        font-weight: 400;
        margin: 0;
        line-height: 1.125rem;
        color: #fbfdfe;
      }
    }
  }
}
