@use '@kronoss/styles/globals' as *;
.HeroWrapper {
  border-radius: 0 0 50px 50px;
  background: #dee8f7;
  padding: 80px 0 0;
  position: relative;

  @include lg {
    padding: 80px + 48px 16px 48px;
  }

  .Hero {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 100px;
    position: relative;
    padding-bottom: 100px;

    @media (max-width: 640px) {
      flex-direction: column;
      gap: 40px;
      padding-bottom: 0;
    }
    .Title {
      font-size: 54px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px;
      margin-bottom: 2rem;

      @include lg {
        font-size: 32px;
        line-height: 110%; /* 35.2px */
        margin-bottom: 10px;
      }
    }
    .Subtitle {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 4rem;

      @include lg {
        font-size: 16px;
        line-height: 20px; /* 125% */
        margin-bottom: 20px;
      }
    }
    .SubscribeLabel {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 1rem;

      @include lg {
        font-size: 14px;
        line-height: 140%; /* 19.6px */
      }
    }

    .HeroImageWrapper {
      @include lg {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          min-width: 100%;
          height: auto;
        }
      }
    }
  }
  .StudiedLanguages {
    position: absolute;
    width: 100%;
    padding: 32px 64px;
    border-radius: 32px;
    border: 1px solid #e5e7eb;
    background: #fff;
    bottom: -9%;

    @include lg {
      position: relative;
      padding: 32px;
      margin-top: 20px;
    }

    .Icon {
      display: flex;
      align-items: center;
      svg {
        border-radius: 8px;
      }
    }

    .Language {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .Description {
        .Name {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px;
        }
        .TeachersCount {
          color: #9ca3af;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
        }
      }
    }
  }
}
