@use '@kronoss/styles/globals' as *;
.Layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;

  .Main {
    flex: 1;
  }
}
