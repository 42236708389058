@use '@kronoss/styles/globals' as *;
.BenefitsCard {
  display: flex;
  padding: 80px 40px;
  align-items: center;
  gap: 75px;
  border-radius: 50px;
  background: #fff;

  @include lg {
    padding: 16px;
    flex-direction: column;
    border-radius: 24px;
    .Content {
      order: 1 !important;
    }
    .Illustration {
      order: 2 !important;
    }
  }

  .Content {
    .Purpose {
      text-transform: uppercase;
      display: flex;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 20px;
      background: #d52f6d;
      margin-bottom: 20px;
      color: #fff;
      width: fit-content;
      font-weight: 700;
      @include lg {
        font-size: 16px;
        line-height: 100%; /* 16px */
        letter-spacing: 0.56px;
      }
    }
    .Title {
      font-size: 44px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 44px */
      letter-spacing: 1px;
      margin-bottom: 20px;
      @include lg {
        font-size: 24px;
        line-height: 120%; /* 28.8px */
        letter-spacing: 0.56px;
      }
    }
    .Subtitle {
      color: var(--Grey-400-small-text, #9ca3af);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 122.222% */
      margin-bottom: 40px;
      @include lg {
        font-size: 12px;
        line-height: 18px; /* 150% */
      }
    }
    .BenefitsListWrapper {
      .BenefitsList {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .BenefitItem {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          color: #16325b;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 120% */
          svg {
            min-width: 20px;
            width: 20px;
            height: 20px;
            color: #efaec6;
          }
        }
      }
    }
  }

  .Illustration {
    @include lg {
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  &.Revert {
    .Content {
      order: 2;
      .Purpose {
        background: #359dc5;
      }

      .BenefitsListWrapper {
        .BenefitsList {
          .BenefitItem {
            svg {
              color: #abd7e8;
            }
          }
        }
      }
    }
    .Illustration {
      order: 1;
    }
  }
}
