@use '@kronoss/styles/globals' as *;
.FooterWrapper {
  background: #0c1b31;
  margin-top: auto;
  .Footer {
    color: #dee8f7;
    padding: 48px 0;
    display: flex;
    flex-direction: column;
    gap: 48px;

    .LogoAction {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      @include lg {
        flex-direction: column;
        gap: 32px;
      }

      .LogoWrapper {
        display: flex;
        align-items: center;
      }
    }
    .Links {
      display: flex;
      align-items: center;
      gap: 1rem;
      color: #dee8f7;

      @include lg {
        font-size: 12px;
        justify-content: center;
      }
    }

    .Divider {
      height: 1px;
      width: 100%;
      background: #dee8f7;
    }

    .Copyright {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;

      @include lg {
        flex-direction: column;
        gap: 32px;

        .CopyrightText {
          order: 2;
          font-size: 14px;
        }

        .Links {
          order: 1;
        }
      }
    }
  }
}
