@use '@kronoss/styles/globals' as *;
.HowWorks {
  display: flex;
  padding: 120px 0;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  //background: #f9fafb;
  .TitleWrapper {
    .Title {
      text-align: center;
      font-size: 42px;
      font-style: normal;
      font-weight: 700;
      line-height: 46px;
    }
    .Subtitle {
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .HowWorksSteps {
    .Pills {
      background: #fff;
      border-radius: 16px;
      margin-bottom: 20px;

      .Pill {
        text-align: center;
        padding: 14px 16px;
        cursor: pointer;
        flex: 1;

        @include lg {
          padding: 8px;
        }

        &.Active {
          cursor: default;
          border-radius: 16px;
          background: #2a5fac;
          color: #ffffff;
        }
      }
    }
    .Steps {
      display: flex;
      background: #fff;
      border-radius: 16px;

      @include lg {
        flex-direction: column;
      }

      .Step {
        flex: 1;
        padding: 24px;
        position: relative;

        .MobileNextStepArrow {
          display: none;
          @include lg {
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -16%;
            margin: auto;
            width: 55px;
            transform: rotate(90deg);
            svg {
              width: 100%;
            }
          }
        }

        .IconsWrapper {
          display: flex;
          align-items: center;
          gap: 1rem;
          justify-content: space-between;
          margin-bottom: 30px;
          .Illustration {
            display: flex;
            width: 80px;
            height: 80px;
            padding: 20px;
            justify-content: center;
            align-items: center;
            border-radius: 28px;
            background: #f7d9e4;
          }

          .NextStepArrow {
            @include lg {
              display: none;
            }
          }
        }

        &:first-child {
          .IconsWrapper {
            .Illustration {
              background: #ebf6fa;
            }
          }
        }

        &:last-child {
          .IconsWrapper {
            .Illustration {
              background: #dee8f7;
            }
          }
        }

        .ContentWrapper {
          .Title {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px;
          }
          .Description {
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
          }
        }
      }
    }
  }
}
